
import React, { useContext, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { emptyIncident, INCIDENT_TYPES, paginationLabels } from "../../constants";
import { ContentDisplayItem, IncidentType } from "../../types";
import { ModalContext } from "../useModal";
import EmptyState from "../EmptyState";
import { prettyDateString } from "src/utils";
import { IncidentsContext } from "./IncidentsProvider";
import { useLocalStorage } from "../../utils/use-local-storage";
import { Box, Select } from "@amzn/awsui-components-react";

export type IncidentsTableProps = {
  incidents: IncidentType[]
}

const DEFAULT_PREFERENCES = {
  pageSize: 100,
  contentDisplay: [
    { id: "date_reported", visible: true },
    { id: "region", visible: true },
    { id: "country", visible: true },
    { id: "location", visible: true },
    { id: "title", visible: true },
    { id: "notes", visible: true },
    { id: "case_priority", visible: true },
    { id: "reference", visible: true },
    { id: "sub_type", visible: true },
    { id: "type", visible: true },
    { id: "urn", visible: true },
    { id: "city", visible: true },
    { id: "bookmarked", visible: true },
    { id: "needs_review", visible: true },
  ],
};

const RawIncidents = () => {
  const { rawIncidents } = useContext(IncidentsContext);
  const [preferences, setPreferences] = useLocalStorage<{
    pageSize: number;
    contentDisplay: readonly ContentDisplayItem[];
  }>("RawIncidentTable-Prefs", DEFAULT_PREFERENCES);

  if (preferences.contentDisplay.length !== DEFAULT_PREFERENCES.contentDisplay.length) {
    setPreferences(DEFAULT_PREFERENCES);
  }

  const { setSelectedIncidents, showModal } = useContext(ModalContext);
  const typeFilterOptions = INCIDENT_TYPES
    .map((t) => {
      return { label: t, value: t };
    });

  typeFilterOptions.unshift({ label: "Choose type", value: "" });

  const [typeFilterOption, setTypeFilterOption] = useState(typeFilterOptions[0]);

  const { items: incidentItems, actions, collectionProps, filterProps, paginationProps } = useCollection(
    rawIncidents,
    {
      filtering: {
        noMatch: (
          <EmptyState
            title="No matches"
            action={
              <Button onClick={() => actions.setFiltering("")}>
                Clear filter
              </Button>
            }
          />
        ),
        filteringFunction(item, filteringText) {
          const thisMatchesByText = item.date_reported?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.region?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.country?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.location?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.title?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.notes?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.reference?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.sub_type?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.type?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            item.city?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase());
          const thisMatchesTheTypeDDL = typeFilterOption.label == "Choose type" || item.type == typeFilterOption.value;
          return thisMatchesByText && thisMatchesTheTypeDDL;
        },
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  const [
    selectedItems,
    setSelectedItems
  ] = React.useState<IncidentType[]>([]);

  const isButtonDisabled = selectedItems.length === 0;

  return (
    <Table
      {...collectionProps}
      ariaLabels={{
        selectionGroupLabel: "Items selection",
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
          } selected`,
      }}
      columnDefinitions={[
        {
          id: "date_reported",
          header: "Date reported",
          cell: (e: IncidentType) => prettyDateString(e.date_reported),
          sortingField: "date_reported",
          isRowHeader: true,
          minWidth: 110,
          width: 180,
        },
        {
          id: "region",
          header: "Region",
          cell: (e: IncidentType) => e.region,
          sortingField: "region",
          minWidth: 70,
          width: 100
        },
        {
          id: "country",
          header: "Country",
          cell: (e: IncidentType) => e.country,
          sortingField: "country",
          minWidth: 120,
          width: 200
        },
        {
          id: "location",
          header: "Location",
          cell: (e: IncidentType) => e.location,
          sortingField: "location",
          minWidth: 100,
          width: 150
        },
        {
          id: "title",
          header: "Title",
          cell: (e: IncidentType) => e.title,
          sortingField: "title",
          minWidth: 160,
          width: 190
        },
        {
          id: "urn",
          header: "URN",
          cell: (e: IncidentType) => e.urn,
          sortingField: "urn",
          minWidth: 80,
          width: 150
        },
        {
          id: "notes",
          header: "Original Notes",
          cell: (e: IncidentType) => <span title={e.notes}>{e.notes.substring(0, 190)}</span>,
          sortingField: "notes",
          minWidth: 400,
          width: 500
        },
        {
          id: "type",
          header: "Type",
          cell: (e: IncidentType) => e.type,
          sortingField: "type",
          minWidth: 100,
          width: 150
        },
        {
          id: "sub_type",
          header: "Subtype",
          cell: (e: IncidentType) => e.sub_type,
          sortingField: "sub_type",
          minWidth: 100,
          width: 150
        },
        {
          id: "case_priority",
          header: "Case Priority",
          cell: (e: IncidentType) => e.case_priority,
          sortingField: "case_priority",
          minWidth: 80,
          width: 150
        },
        {
          id: "reference",
          header: "Reference",
          cell: (e: IncidentType) => e.reference,
          sortingField: "reference",
          minWidth: 80,
          width: 150
        },
        {
          id: "city",
          header: "City",
          cell: (e: IncidentType) => e.city,
          sortingField: "city",
          minWidth: 80,
          width: 150
        },
        {
          id: "bookmarked",
          header: "Bookmarked",
          cell: (e: IncidentType) => e.bookmarked === 1 ? "Yes" : "No",
          sortingField: "bookmarked",
          minWidth: 20,
          width: 150
        },
        {
          id: "needs_review",
          header: "Needs Review",
          cell: (e: IncidentType) => (e.needs_review === 1 ? "Yes" : "No"),
          sortingField: "needs_review",
          minWidth: 20,
          width: 150
        },

      ]}
      columnDisplay={preferences.contentDisplay}
      enableKeyboardNavigation
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Search by location, title, etc."
          filteringAriaLabel="Filter incidents"
        />
      }
      header={
        <Header
          actions={
            <SpaceBetween
              direction="horizontal"
              size="xs"
            >

              <ButtonDropdown
                onItemClick={(evt) => {
                  if (evt.detail.id === "incident_details") {
                    showModal("incident");
                  } else if (evt.detail.id === "workbench") {
                    showModal("workbench");
                  }
                  setSelectedItems([]);
                }}
                items={[
                  {
                    text: "View details",
                    id: "incident_details",
                    disabled: isButtonDisabled
                  },
                  {
                    text: "Edit in workbench",
                    id: "workbench",
                    disabled: isButtonDisabled
                  }
                ]}
              >
                Actions
              </ButtonDropdown>
            </SpaceBetween>
          }
        >
          <Box>
            <Select
              selectedOption={typeFilterOption}
              options={typeFilterOptions}
              onChange={(evt) => {
                const selOpt = typeFilterOptions.find((fo) => fo.value === evt.detail.selectedOption.value!);
                setTypeFilterOption(selOpt!);
              }} />

          </Box>
        </Header>
      }
      items={incidentItems}
      loadingText="Loading resources"
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={
        <CollectionPreferences
          title="Preferences"
          cancelLabel="Cancel"
          confirmLabel="Confirm"
          preferences={preferences}
          contentDisplayPreference={{
            options: [
              { id: "date_reported", label: "Date Reported" },
              { id: "region", label: "Region" },
              { id: "country", label: "Country" },
              { id: "location", label: "Location" },
              { id: "title", label: "Title" },
              { id: "notes", label: "Original Notes" },
              { id: "type", label: "Type" },
              { id: "sub_type", label: "Subtype" },
              { id: "case_priority", label: "Case Priority" },
              { id: "reference", label: "Reference" },
              { id: "city", label: "City" },
              { id: "bookmarked", label: "Bookmarked" },
              { id: "urn", label: "URN" },
              { id: "needs_review", label: "Needs Review" },
            ],
          }}
          pageSizePreference={{
            title: "Page Size",
            options: [
              { value: 10, label: "10" },
              { value: 20, label: "20" },
              { value: 50, label: "50" },
              { value: 100, label: "100" },
            ],
          }}

          onConfirm={({ detail }) => {
            const { pageSize, contentDisplay } = detail;
            if (typeof pageSize === "number" && contentDisplay) {
              setPreferences({
                pageSize,
                contentDisplay,
              });
            }
          }}

        />
      }
      resizableColumns
      selectedItems={selectedItems}
      selectionType="multi"
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
        setSelectedIncidents(detail.selectedItems.length > 0 ? detail.selectedItems : [emptyIncident]);
      }}
      trackBy="id"
      wrapLines
    />
  );
}

export default RawIncidents;
