import React, { createContext, useContext, useState, useEffect } from "react";
import { emptyIncident, EmptyIncidentRisk } from "../../constants";
import { IncidentType, IncidentsGroupedByDate } from "src/types";
import { RoleContext } from "../RoleProvider";
import { getBaseUrl, getMonthAndYear } from "src/utils";

const initialState = {
    rawIncidents: [emptyIncident],
    untriagedIncidents: [emptyIncident],
    rawIncidentsGroupedByDay: {},
    needsReviewIncidentsGroupedByDay: {},
    bookmarkedIncidentsGroupedByDay: {},
    duplicateIncidentsGroupedByDay: {},
    nonAttributableIncidentsGroupedByMonth: {},//monthString
    triagedIncidentsGroupedByMonth: {},
    completeIncidents: [emptyIncident],
    naAndDuplicateIncidents: [emptyIncident],
    incidentRisks: [EmptyIncidentRisk],
    postNewIncidentRisk: (_subRisk_ID: number, _incident_ID: string) => { },
    deleteIncidentRisk: (_subRisk_ID: number, _incident_ID: string) => { },
    putIncident: (_incident: IncidentType) => { },
    setNeedsRefetch: () => {},
};

export const IncidentsContext = createContext(initialState);

export const IncidentsProvider = (props: { children: JSX.Element }) => {
    //Triaged incidents are more like "incident-risks", so completeIncidents might contain several items for a given risk
    const [untriagedIncidents, setUntriagedIncidents] = useState(initialState.untriagedIncidents);
    const [rawIncidents, setRawIncidents] = useState([emptyIncident]);
    const [rawIncidentsGroupedByDay, setRawIncidentsGroupedByDay] = useState<IncidentsGroupedByDate>({});
    const [needsReviewIncidentsGroupedByDay, setNeedsReviewIncidentsGroupedByDay] = useState<IncidentsGroupedByDate>({});
    const [bookmarkedIncidentsGroupedByDay, setBookmarkedIncidentsGroupedByDay] = useState<IncidentsGroupedByDate>({});
    const [duplicateIncidentsGroupedByDay, setDuplicateIncidentsGroupedByDay] = useState<IncidentsGroupedByDate>({});
    const [nonAttributableIncidentsGroupedByMonth, setNonAttributableIncidentsGroupedByMonth] = useState<IncidentsGroupedByDate>({});
    const [triagedIncidentsGroupedByMonth, setTriagedIncidentsGroupedByMonth] = useState<IncidentsGroupedByDate>({});

    const [completeIncidents, setCompleteIncidents] = useState(initialState.completeIncidents);
    const [naAndDuplicateIncidents, setNaAndDuplicateIncidents] = useState(initialState.naAndDuplicateIncidents);

    const [incidentRisks, setIncidentRisks] = useState(initialState.incidentRisks);
    const untriagedEndpoint = `${getBaseUrl()}/untriagedIncidents`;
    const triagedEndpoint = `${getBaseUrl()}/triagedIncidents`;
    const { loggedInUser, token, mostPowerfulRole, authenticated, fakeLoggedInUser, userIsRiskAdmin, userIsRiskManager, roleFetchComplete } = useContext(RoleContext);
    const [incidentsAreDirtyCounter, setIncidentsAreDirtyCounter] = useState(0);


    const dateSorter = (a: any, b: any) => {
        const dateStringA = a.date_reported.split(" ")[0]
        const dateStringB = b.date_reported.split(" ")[0]
        const dateA = new Date(dateStringA);
        const dateB = new Date(dateStringB);
        if (dateA < dateB) {
            return -1
        } if (dateA > dateB) {
            return 1
        } return 0;
    };

    const fetchTriagedIncidents = async () => {
       
        let url = triagedEndpoint;
        if(userIsRiskAdmin && fakeLoggedInUser !== "") {
            url =  `${triagedEndpoint}?alias=${fakeLoggedInUser}`;
        }
        else if(mostPowerfulRole === "manager") {
            url =  `${triagedEndpoint}?alias=${loggedInUser}`;
        }
        const res = await fetch(url,
            { headers: new Headers({ "wowie": token }) });
        return res.json();
    }

    const fetchUntriagedIncidents = async () => {
        const res = await fetch(untriagedEndpoint,
            { headers: new Headers({ "wowie": token }) });
        return res.json();
    }

    const fetchIncidentRisks = async () => {
        const res = await fetch(`${getBaseUrl()}/riskIncident`,
            { headers: new Headers({ "wowie": token }) });
        return res.json();
    }

    const postNewIncidentRisk = async (subRisk_ID: number, incident_ID: string) => {
        const response = await fetch(`${getBaseUrl()}/riskIncident`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "wowie": token
            },
            method: "POST",
            body: JSON.stringify({ subRisk_ID, incident_ID })
        });
        const result = await response.json();

        if (response.status == 200) {
            const tempIncidentRisks = [...incidentRisks ?? EmptyIncidentRisk];
            tempIncidentRisks.push({ subRiskID: subRisk_ID.toString(), incidentID: incident_ID });
            setIncidentRisks(tempIncidentRisks);
        }
    };

    // PUTs a request to update the incident and refreshes the references to the incident in either the TriagedIncidents or UntriagedIncidents array
    const putIncident = async (updatedIncident: IncidentType) => {
       
        const response = await fetch(`${getBaseUrl()}/incident`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "wowie": token
            },
            method: "PUT",
            body: JSON.stringify({
                analystNotes: updatedIncident.analyst_notes,
                bookmarked: updatedIncident.bookmarked,
                city: updatedIncident.city,
                country: updatedIncident.country,
                duplicate: updatedIncident.duplicate,
                incidentID: updatedIncident.id,
                title: updatedIncident.title,
                type: updatedIncident.type,
                notes: updatedIncident.notes,
                location: updatedIncident.location,
                region: updatedIncident.region,
                needs_review: updatedIncident.needs_review,
                source: updatedIncident.source,
                stateProvince: updatedIncident.state_province,
                status: updatedIncident.status,
                sourceType: updatedIncident.source_type,
                triageStatus: updatedIncident.triage_status,
                threatOriginator: updatedIncident.threat_originator,
                updatedBy: loggedInUser
            })
        });
        const result = await response.json();

        if (response.status == 200) {
            setIncidentsAreDirtyCounter((prev) => prev + 1);
        }
    };

    const deleteIncidentRisk = async (subRisk_ID: number, incident_ID: string) => {
        const response = await fetch(`${getBaseUrl()}/riskIncident`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "wowie": token
            },
            method: "DELETE",
            body: JSON.stringify({ subRisk_ID, incident_ID })
        });
        const result = await response.json();

        if (response.status == 200) {
            const newIncidentRisks = [...incidentRisks];
            const incidentRiskToRemove = incidentRisks.filter((ir) => parseInt(ir.subRiskID) === subRisk_ID && ir.incidentID === incident_ID)[0];
            const indexToRemove = newIncidentRisks.indexOf(incidentRiskToRemove);
            newIncidentRisks.splice(indexToRemove, 1);

            setIncidentRisks(newIncidentRisks);
        }
    };

    useEffect(() => {
        const fetchIncidentsForManager = async() => {
            const [complete, incidRisks] = await Promise.all([
                fetchTriagedIncidents(),
                fetchIncidentRisks()
            ]);

            setCompleteIncidents(complete.incidents);
            setIncidentRisks(incidRisks);
        }
        
        const fetchIncidentsForAdmin = async () => {
            const [complete, incomplete, incidRisks] = await Promise.all([
                fetchTriagedIncidents(),
                fetchUntriagedIncidents(),
                fetchIncidentRisks()
            ]);

            setCompleteIncidents(complete.incidents);
            setUntriagedIncidents(incomplete.incidents);
            setIncidentRisks(incidRisks.incidentRisks);
            const combined: IncidentType[] = complete.incidents.concat(incomplete.incidents);
            setNaAndDuplicateIncidents(combined.filter((incd) => ((incd.duplicate === 1) || (incd.triage_status === "n/a"))));

            const sortedIncidents = combined
                .filter((x: any) => x.date_reported?.trim().length > 0)//pending some sort of extraction-time cleanup of dateless incidents
                .sort(dateSorter);

            const tempRawIncidents: IncidentType[] = [];
            const tempCompleteIncidents: IncidentType[] = [];
            const tempRawIncidentsGroupedByDay: IncidentsGroupedByDate = {};
            const tempNeedsReviewIncidentsGroupedByDay: IncidentsGroupedByDate = {};
            const tempBookmarkedIncidentsGroupedByDay: IncidentsGroupedByDate = {};
            const tempDuplicateIncidentsGroupedByDay: IncidentsGroupedByDate = {};
            const tempnonAttributableIncidentsGroupedByMonth: IncidentsGroupedByDate = {};
            const tempTriagedIncidentsGroupedByDay: IncidentsGroupedByDate = {};

            for (let i = 0; i < sortedIncidents.length; i++) {
                if (sortedIncidents[i].date_reported.length > 10) {
                    const theDateString = sortedIncidents[i].date_reported.split("T")[0];
                    const dateParts = theDateString.split("-");
                    const theDate = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]), parseInt(dateParts[2]));
                    const monthString = getMonthAndYear(theDate);

                    if (sortedIncidents[i].needs_review === 1) {
                        if (!tempNeedsReviewIncidentsGroupedByDay[theDateString]) {
                            tempNeedsReviewIncidentsGroupedByDay[theDateString] = [];
                        }
                        tempNeedsReviewIncidentsGroupedByDay[theDateString].push(sortedIncidents[i]);
                    }
                    if (sortedIncidents[i].bookmarked === 1) {
                        if (!tempBookmarkedIncidentsGroupedByDay[theDateString]) {
                            tempBookmarkedIncidentsGroupedByDay[theDateString] = [];
                        }
                        tempBookmarkedIncidentsGroupedByDay[theDateString].push(sortedIncidents[i]);
                    }
                    if (sortedIncidents[i].duplicate === 1) {
                        if (!tempDuplicateIncidentsGroupedByDay[theDateString]) {
                            tempDuplicateIncidentsGroupedByDay[theDateString] = [];
                        }
                        tempDuplicateIncidentsGroupedByDay[theDateString].push(sortedIncidents[i]);
                    }
                    if (sortedIncidents[i].triage_status === "raw") {
                        tempRawIncidents.push(sortedIncidents[i]);
                        if (!tempRawIncidentsGroupedByDay[theDateString]) {
                            tempRawIncidentsGroupedByDay[theDateString] = [];
                        }
                        tempRawIncidentsGroupedByDay[theDateString].push(sortedIncidents[i]);
                    }
                    if (sortedIncidents[i].triage_status === "n/a") {
                        if (!tempnonAttributableIncidentsGroupedByMonth[monthString]) {
                            tempnonAttributableIncidentsGroupedByMonth[monthString] = [];
                        }
                        tempnonAttributableIncidentsGroupedByMonth[monthString].push(sortedIncidents[i]);
                    }
                    if (sortedIncidents[i].triage_status === "complete") {
                        tempCompleteIncidents.push(sortedIncidents[i])
                        if (!tempTriagedIncidentsGroupedByDay[monthString]) {
                            tempTriagedIncidentsGroupedByDay[monthString] = [];
                        }
                        tempTriagedIncidentsGroupedByDay[monthString].push(sortedIncidents[i]);
                    }
                }
            };

            setNeedsReviewIncidentsGroupedByDay(tempNeedsReviewIncidentsGroupedByDay);
            setBookmarkedIncidentsGroupedByDay(tempBookmarkedIncidentsGroupedByDay);
            setDuplicateIncidentsGroupedByDay(tempDuplicateIncidentsGroupedByDay);
            setNonAttributableIncidentsGroupedByMonth(tempnonAttributableIncidentsGroupedByMonth);
            setTriagedIncidentsGroupedByMonth(tempTriagedIncidentsGroupedByDay);
            setCompleteIncidents(tempCompleteIncidents);
            setRawIncidents(tempRawIncidents);
            setRawIncidentsGroupedByDay(tempRawIncidentsGroupedByDay);
        }
        const fxn = async () => {
            if (loggedInUser.length > 1 && authenticated && roleFetchComplete) {
                if(userIsRiskAdmin){
                    fetchIncidentsForAdmin()
                } else if(userIsRiskManager){
                    fetchIncidentsForManager()
                }
            }
        };
        fxn();
    }, [loggedInUser, incidentsAreDirtyCounter, completeIncidents.length, untriagedIncidents.length, fakeLoggedInUser, roleFetchComplete]);

    const setNeedsRefetch = () => {
        setIncidentsAreDirtyCounter((prev) => prev + 1);
    }

    return (<IncidentsContext.Provider value={{
        untriagedIncidents, rawIncidents, rawIncidentsGroupedByDay, needsReviewIncidentsGroupedByDay, bookmarkedIncidentsGroupedByDay,
        duplicateIncidentsGroupedByDay, nonAttributableIncidentsGroupedByMonth, triagedIncidentsGroupedByMonth, completeIncidents, naAndDuplicateIncidents, 
        incidentRisks, postNewIncidentRisk, putIncident, deleteIncidentRisk, setNeedsRefetch
    }}>
        {props.children}
    </IncidentsContext.Provider>)
}