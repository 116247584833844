import React, { useContext, useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Toggle from "@amzn/awsui-components-react/polaris/toggle";
import Button from "@amzn/awsui-components-react/polaris/button";
import { Container, Flashbar, FormField, Input, Select, SelectProps, Textarea } from "@amzn/awsui-components-react";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";

import { ModalContext } from "../useModal";
import { emptyIncident, IncidentSources, IncidentStatuses, IncidentSourceTypes, ThreatOriginators } from "src/constants";
import { RiskContext } from "../RiskProvider";
import { IncidentsContext } from "./IncidentsProvider";
import { IncidentRiskType, IncidentType, SubRiskType } from "src/types";

const EditIncidentModal = () => {
  const { isShowing, hideModals, selectedIncidents, setSelectedIncidents } = React.useContext(ModalContext);
  const [titleValue, setTitleValue] = useState("");
  const [dateReportedValue, setDateReportedValue] = useState("");
  const [displayIDValue, setDisplayIDValue] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [analystNotesValue, setAnalystNotesValue] = useState("");
  const [originalAnalystNotesValue, setOriginalAnalystNotesValue] = useState("");
  const [notesValue, setNotesValue] = useState("");
  const [bookmarkedValue, setBookmarkedValue] = useState(0);
  const [selectedRiskID, setSelectedRiskID] = useState("0");
  const [selectedSubRiskID, setSelectedSubRiskID] = useState("0");
  const [stateProvinceValue, setStateProvinceValue] = useState("");

  const { state } = useContext(RiskContext);
  const { incidentRisks, postNewIncidentRisk, putIncident, deleteIncidentRisk, setNeedsRefetch } = useContext(IncidentsContext);
  const [subRisksBeingDisplayed, setSubRisksBeingDisplayed] = useState<SubRiskType[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<SelectProps.Option>({});
  const [selectedSource, setSelectedSource] = useState<SelectProps.Option>({});
  const [selectedSourceType, setSelectedSourceType] = useState<SelectProps.Option>({});
  const [selectedThreatOriginator, setSelectedThreatOriginator] = useState<SelectProps.Option>({});
  const [triageStatus, setTriageStatus] = useState("raw");

  const [selectedIncidentIndex, setSelectedIncidentIndex] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const riskCategoryOptions = state.risks.map((r) => {
    return (
      <option key={`risk_option_${r.risk_number + 1}`} value={r.risk_number}>
        {r.risk_name_full}
      </option>
    );
  });

  riskCategoryOptions.unshift(
    <option key={`risk_option_0`} value={0}>
      Choose
    </option>
  );

  const statusOptions = IncidentStatuses.map((stat) => {
    return { label: stat, value: stat }
  });

  const sourceOptions = IncidentSources.map((source) => {
    return { label: source, value: source };
  });

  const sourceTypeOptions = IncidentSourceTypes.map((st) => {
    return { label: st, value: st };
  });

  const threatOriginatorOptions = ThreatOriginators.map((st) => {
    return { label: st, value: st };
  });

  let subRiskOptions;
  let risksAssociatedWithThisIncident: IncidentRiskType[] = [];

  const [needsReviewLocal, setNeedsReviewLocal] = useState(1);


  const [associatedRiskNames, setAssociatedRiskNames] = useState([{
    subRiskID: 0,
    riskFullName: ""
  }]);
  const [newRiskPanelOpen, setNewRiskPanelOpen] = useState(false);
  const [referenceRef, setReferenceRef] = useState("");
  const [isDuplicateValue, setIsDuplicateValue] = useState(1);

  const BatchMode = selectedIncidents.length > 1;
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [cancelButtonText, setCancelButtonText] = useState("Cancel")

  const getSubRisksByRiskNumberThatAreNotAlreadyAssociated = (riskNum: string, currentlyAssociatedSubRisks: IncidentRiskType[]) => {
    const rawSubRisks = state.subRisks.filter((sr) => sr.risk_id == Number.parseInt(riskNum));
    if (currentlyAssociatedSubRisks.length === 0) {
      return rawSubRisks;
    }
    const currentlySelectedSubRiskIDs = currentlyAssociatedSubRisks.map((sr) => parseInt(sr.subRiskID));
    const subRisksThatArentAlreadyPicked = rawSubRisks.filter((raw) => {

      const justTheSubRiskIDs = currentlyAssociatedSubRisks
        .map((x) => x.subRiskID);

      const theSubRiskIDsThatArentAlreadyPicked = justTheSubRiskIDs.filter((thing) => currentlySelectedSubRiskIDs.indexOf(raw.acs_risk_dim_sk) == -1)
      return theSubRiskIDsThatArentAlreadyPicked.length > 0;
    });

    return subRisksThatArentAlreadyPicked;
  }

  useEffect(() => {
    const {
      analyst_notes,
      bookmarked,
      city,
      country,
      date_reported,
      display_id,
      duplicate,
      location,
      needs_review,
      notes,
      reference,
      region,
      state_province,
      status,
      source,
      source_type,
      threat_originator,
      title,
      type,
      triage_status
    } = selectedIncidents[selectedIncidentIndex];

    setCityValue(city)
    setTitleValue(title);
    setDateReportedValue(date_reported);
    setDisplayIDValue(display_id);
    setTypeValue(type);
    setRegionValue(region);
    setCountryValue(country);
    setLocationValue(location);
    setAnalystNotesValue(analyst_notes);
    setOriginalAnalystNotesValue(analyst_notes);
    setNotesValue(notes);
    setBookmarkedValue(bookmarked)
    setNeedsReviewLocal(needs_review);
    setReferenceRef(reference);
    setIsDuplicateValue(duplicate);
    setTriageStatus(triage_status);

    setSelectedStatus(statusOptions.filter((e) => e.value === status)[0]);
    setSelectedSource(sourceOptions.filter((e) => e.value === source)[0]);
    setSelectedSourceType(sourceTypeOptions.filter((e) => e.value === source_type)[0]);
    setSelectedThreatOriginator(threatOriginatorOptions.filter((e) => e.value === threat_originator)[0]);
    setStateProvinceValue(state_province);
  }, [selectedIncidentIndex, selectedIncidents[selectedIncidentIndex].id]);

  useEffect(() => {
    const { id } = selectedIncidents[selectedIncidentIndex];
    if (incidentRisks) {
      risksAssociatedWithThisIncident = incidentRisks.filter((ir) => ir.incidentID === id);
      const riskList = risksAssociatedWithThisIncident.map((ir) => Number.parseInt(ir.subRiskID));

      //the ones to show in our list of already-associated subRisks
      const relevantSubRisks = state.subRisks
        .filter((sr) => riskList
          .indexOf(sr.acs_risk_dim_sk) != -1)
        .map((rsr) => {
          return {
            subRiskID: rsr.acs_risk_dim_sk,
            riskFullName: rsr.sub_risk_full_name
          }
        });

      setAssociatedRiskNames(relevantSubRisks);
      // only show the ones that are not already associated with this incident
      setSubRisksBeingDisplayed(getSubRisksByRiskNumberThatAreNotAlreadyAssociated(selectedRiskID, risksAssociatedWithThisIncident));

    } else {
      setSubRisksBeingDisplayed(getSubRisksByRiskNumberThatAreNotAlreadyAssociated(selectedRiskID, risksAssociatedWithThisIncident));
    }
  }, [incidentRisks?.length, selectedRiskID, selectedIncidents[selectedIncidentIndex].id]);

  useEffect(() => {
    setSaveButtonText(BatchMode && selectedIncidentIndex < selectedIncidents.length - 1 ? "Save & next" : "Save");
  }, [BatchMode, selectedIncidentIndex]);

  subRiskOptions = subRisksBeingDisplayed.map((sr) => {
    return (
      <option
        key={`sr_option_${sr.acs_risk_dim_sk}`}
        value={sr.acs_risk_dim_sk}
      >
        {sr.sub_risk_full_name}
      </option>
    );
  });

  subRiskOptions.unshift(
    <option key={`sr_option_0`} value={0}>
      Choose
    </option>
  );

  const checkForErrors = () => {
    let hasErrors = false;
    const newErrors: string[] = [];
    if (triageStatus === "complete") {
      if (associatedRiskListItems.length === 0) {
        newErrors.push("Need to attribute at least one risk");
        setShowErrors(true);
        hasErrors = true;
      }
      if (!selectedStatus) {
        newErrors.push("Need to select a status");
        setShowErrors(true);
        hasErrors = true;
      }
      if (!selectedSourceType) {
        newErrors.push("Need to select a source type");
        setShowErrors(true);
        hasErrors = true;
      }
      if (!selectedSource) {
        newErrors.push("Need to select a source");
        setShowErrors(true);
        hasErrors = true;
      }
      if (!selectedThreatOriginator) {
        newErrors.push("Need to select a threat originator");
        setShowErrors(true);
        hasErrors = true;
      }
    }
    setErrors(newErrors);
    return hasErrors;
  }

  const updateIncident = () => {
    const updatedIncident: IncidentType = {
      ...selectedIncidents[selectedIncidentIndex],
      analyst_notes: (originalAnalystNotesValue !== analystNotesValue && analystNotesValue !== '') ? analystNotesValue : '',
      bookmarked: bookmarkedValue,
      city: cityValue || '',
      country: countryValue,
      id: selectedIncidents[selectedIncidentIndex].id,
      date_reported: dateReportedValue,
      display_id: displayIDValue,
      duplicate: isDuplicateValue,
      reference: referenceRef,
      title: titleValue,
      type: typeValue,
      notes: notesValue,
      location: locationValue,
      region: regionValue,
      needs_review: needsReviewLocal,
      state_province: stateProvinceValue || '',
      status: selectedStatus?.value || '',
      source: selectedSource?.value || '',
      source_type: selectedSourceType?.value || '',
      threat_originator: selectedThreatOriginator?.value || '',
      triage_status: triageStatus === "complete" ? "complete" : "n/a",
    }
    putIncident(updatedIncident);
    if (!BatchMode) {
      setSelectedIncidents([emptyIncident]);
    } else {
      selectedIncidents[selectedIncidentIndex] = updatedIncident;
    }

    setNotesValue("");//TODO: do I need this
  }

  const associatedRiskListItems = associatedRiskNames.map((risk, idx) => (<li key={risk.subRiskID + idx}>
    <div className="spread-apart">
      <span>{risk.riskFullName}</span>
      <Button onClick={(_evt) => deleteIncidentRisk(risk.subRiskID, selectedIncidents[selectedIncidentIndex].id)}>remove</Button>
    </div>
  </li>
  ));

  return (
    <Modal
      footer={
        <Container>
          {BatchMode && <Box float="left">{selectedIncidentIndex + 1} of {selectedIncidents.length}</Box>}
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {BatchMode && <Button disabled={selectedIncidentIndex === 0} variant="link" onClick={(_event) => {
                setSelectedIncidentIndex((prev) => prev - 1)
                setErrors([""]);
                setShowErrors(false);
              }}>Prev</Button>}
              <Button variant="link" onClick={(_event) => {
                if (BatchMode) {
                  setNeedsRefetch();
                }
                hideModals();
                setErrors([""]);
                setShowErrors(false);
                setSelectedIncidents([emptyIncident]);
                setSelectedIncidentIndex(0);
              }}>{cancelButtonText}</Button>
              <Button variant="link" onClick={(_event) => {
                if (!checkForErrors()) {
                  updateIncident();
                  if (BatchMode) {
                    if (selectedIncidentIndex < selectedIncidents.length - 1) {
                      setSelectedIncidentIndex((prev) => prev + 1);
                    } else {
                      hideModals();
                      setSelectedIncidentIndex(0);
                    }
                    setCancelButtonText("Close");
                  } else {
                    hideModals();
                    setSelectedIncidentIndex(0);
                  }

                  setErrors([""]);
                  setShowErrors(false);
                }
              }}>{saveButtonText}</Button>
              {BatchMode && <Button disabled={selectedIncidentIndex === selectedIncidents.length - 1} variant="link" onClick={(_event) => {
                setSelectedIncidentIndex((prev) => prev + 1)
                setErrors([""]);
                setShowErrors(false);
              }}>Next</Button>}
            </SpaceBetween>
          </Box>
        </Container>
      }
      header={selectedIncidents[selectedIncidentIndex].display_id || selectedIncidents[selectedIncidentIndex].id}
      onDismiss={() => hideModals()}
      size="max"
      visible={isShowing("workbench")}
    >
      <Container>
        {showErrors && <Flashbar
          items={[
            {
              content: <ul>{errors.map((e, index) => (<li key={`error-${index}`}>{e}</li>))}</ul>,
              dismissible: true,
              id: "message_1",
              onDismiss: () => { },
              type: "warning",
            }
          ]}
        />}
        <div className="modal-body">
          <div className="edit-incident">
            <div className="left-col">
              <div className="admin-section">
                <FormField label="Title">
                  <Input
                    value={titleValue}
                    onChange={event =>
                      setTitleValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="Date reported">
                  <Input
                    value={dateReportedValue}
                    disabled
                    onChange={event =>
                      setDateReportedValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="Region">
                  <Input
                    value={regionValue}
                    onChange={event =>
                      setRegionValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="Country">
                  <Input
                    value={countryValue}
                    onChange={event =>
                      setCountryValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="State/Province">
                  <Input
                    value={stateProvinceValue}
                    onChange={event =>
                      setStateProvinceValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="City">
                  <Input
                    value={cityValue}
                    onChange={event =>
                      setCityValue(event.detail.value)
                    }
                  />
                </FormField>

                <FormField label="Location">
                  <Input
                    value={locationValue}
                    onChange={event =>
                      setLocationValue(event.detail.value)
                    }
                  />
                </FormField>
              </div>
              <FormField label="Type">
                <Input
                  value={typeValue}
                  onChange={event =>
                    setTypeValue(event.detail.value)
                  }
                />
              </FormField>
              <div className="space-above">
                <label>Notes</label>
                <div className="space-above">
                  <Textarea
                    readOnly
                    rows={15}
                    onChange={({ detail }) => setNotesValue(detail.value)}
                    value={notesValue}
                  />
                </div>
              </div>
              <div className="space-above">
                <label>Analyst notes</label>
                <div className="space-above">
                  <Textarea
                    rows={5}
                    onChange={({ detail }) => setAnalystNotesValue(detail.value)}
                    value={analystNotesValue}
                  />
                </div>
              </div>
              <div className="space-above">
                <ColumnLayout columns={2}>
                  <Toggle
                    disabled={isDuplicateValue === 1}
                    onChange={({ detail }) => {
                      setTriageStatus(detail.checked ? "complete" : "n/a");
                      if (detail.checked) {
                        setIsDuplicateValue(0);
                      }
                    }}
                    checked={triageStatus === "complete"}
                  >
                    Attributable
                  </Toggle>
                  <SpaceBetween direction="vertical" size="s">
                    <Toggle
                      onChange={({ detail }) =>
                        setNeedsReviewLocal(detail.checked ? 1 : 0)
                      }
                      checked={needsReviewLocal === 1}
                    >
                      Needs review
                    </Toggle>

                    <Toggle
                      checked={isDuplicateValue === 1}
                      disabled={triageStatus === "complete"}
                      onChange={({ detail }) =>
                        setIsDuplicateValue(detail.checked ? 1 : 0)
                      }
                    >
                      Is duplicate
                    </Toggle>

                    <Toggle
                      onChange={({ detail }) =>
                        setBookmarkedValue(detail.checked ? 1 : 0)
                      }
                      checked={bookmarkedValue === 1}
                    >
                      Bookmark
                    </Toggle>
                  </SpaceBetween>

                </ColumnLayout>
              </div>
            </div>
            {triageStatus === "complete" &&
              <div className="right-col">
                <fieldset>
                  <h4>Associated risk(s)</h4>
                  <ul>
                    {associatedRiskListItems}
                  </ul>

                  {!newRiskPanelOpen && <div className="space-above">
                    <label>Assign:</label><Button onClick={(_evt) => setNewRiskPanelOpen(true)}>new risk</Button>
                  </div>
                  }

                  {newRiskPanelOpen && <>
                    <div className="space-above">
                      <h3>Associate new risk</h3>
                      <div className="risk-select-may-wrap">
                        <label className="mx-2">Risk Category:</label>
                        <select
                          value={selectedRiskID}
                          onChange={(evt) => {
                            setSelectedRiskID(evt.currentTarget.value);
                          }}
                        >
                          {riskCategoryOptions}
                        </select>
                      </div>


                      {selectedRiskID !== "0" && (
                        <div className="risk-select-may-wrap">
                          <label className="mx-2">Sub Risk:</label>
                          <select
                            value={selectedSubRiskID}
                            onChange={(evt) => {
                              setSelectedSubRiskID(evt.target.value);
                            }}
                          >
                            {subRiskOptions}
                          </select>
                        </div>
                      )}

                    </div>
                    <div className="space-above">
                      <Button disabled={selectedSubRiskID === "0" || selectedRiskID === "0"} onClick={(_evt) => {
                        postNewIncidentRisk(Number.parseInt(selectedSubRiskID), selectedIncidents[selectedIncidentIndex].id);
                        setSelectedRiskID("0");
                        setSelectedSubRiskID("0");
                      }}>Associate with incident</Button>
                    </div>
                  </>
                  }
                </fieldset>
                <div>
                  <SpaceBetween direction="vertical" size="xs">
                    <FormField label="Status:">
                      <Select
                        selectedOption={selectedStatus}
                        onChange={({ detail }) => setSelectedStatus(detail.selectedOption)}
                        options={statusOptions}
                      />
                    </FormField>

                    <FormField label="Source:">
                      <Select
                        selectedOption={selectedSource}
                        onChange={({ detail }) => setSelectedSource(detail.selectedOption)}
                        options={sourceOptions}
                      />
                    </FormField>

                    <FormField label="Source type:">
                      <Select
                        selectedOption={selectedSourceType}
                        onChange={({ detail }) => setSelectedSourceType(detail.selectedOption)}
                        options={sourceTypeOptions}
                      />
                    </FormField>

                    <FormField label="Threat originator:">
                      <Select
                        selectedOption={selectedThreatOriginator}
                        onChange={({ detail }) => setSelectedThreatOriginator(detail.selectedOption)}
                        options={threatOriginatorOptions}
                      />
                    </FormField>
                  </SpaceBetween>
                </div>

              </div>
            }
          </div>
        </div>
      </Container>
    </Modal >
  );
}

export default EditIncidentModal;