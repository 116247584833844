//copied from consequence assessment
import { useContext, useEffect, useState } from "react";
import {
  Select,
  SelectProps,
  Modal,
  SpaceBetween,
  Box,
  Header,
  ColumnLayout,
  Popover,
  Icon,
} from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { EntityAndManagerContext } from "./EntityAndManagerProvider";
import EffectivenessDefinitionsLayout from "./EffectivenessDefinitionsLayout";
import { ModalContext } from "./useModal";
import { ControlDataContext } from "./ControlsProvider";
import EmptyState from "./EmptyState";
import { IsUtilizedOptions, IsUtilizedValues, EffectivenessOptions, emptyLocation } from "../constants";
import { getMatchesCountText } from "../utils";
import { SurveyViewOfControlType } from "src/types";
import { SurveyContext } from "./SurveyProvider";
import CancelWarning from "./CancelWarning";

const ControlSurveyModal = () => {
  const { isShowing, hideModals, selectedEntity, setSelectedLocation } = useContext(ModalContext);
  const { controlCategories } = useContext(ControlDataContext);
  const { copyFromExistingSurvey, state, dispatch, postSurveyAnswers, postPartialSurvey } = useContext(SurveyContext);
  const { entities, refreshEntitiesAndManagers } = useContext(EntityAndManagerContext);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [showChangesWarning, setShowChangesWarning] = useState(false);
  const [hasSeenUnsavedChangesWarning, setHasSeenUnsavedChangesWarning] = useState(false);
  const [warningText, setWarningText] = useState('');
  const [selectedCopyFromOption, setSelectedCopyFromOption] = useState<SelectProps.Option>();

  useEffect(() => {
    setShowChangesWarning(false);
  }, [selectedEntity]);


  const onCloseModal = () => {
    if (hasPendingChanges && !hasSeenUnsavedChangesWarning) {
      setWarningText('You have made changes that aren\'t saved. Click "Save" to keep your changes or "Cancel" to discard them.');
      setShowChangesWarning(hasPendingChanges);
      setHasSeenUnsavedChangesWarning(true);
      return false;
    }
    setHasPendingChanges(false);
    hideModals();
    setHasSeenUnsavedChangesWarning(false);
    setShowChangesWarning(false);
    setSelectedLocation(emptyLocation);
  }


  const { items: itemsFromUseCollection, filteredItemsCount, collectionProps, filterProps } = useCollection(state.surveyQuestions, {
    filtering: {
      noMatch: (
        <EmptyState
          title="No Controls Found"
        />
      ),
      filteringFunction: (item, filteringText) => {
        return item.category_name.toLowerCase().includes(filteringText.toLowerCase())
          || item.control_name.toLowerCase().includes(filteringText.toLowerCase());
      },
    },
  });

  const copyFromOptions = entities
    .filter((e) => (e.survey_completed === 1))
    .sort((t1, t2) => t1.site.localeCompare(t2.site))
    .map((e) => {
      return { label: e.site, value: e.entityId };
    });

  copyFromOptions.unshift({ label: "Copy from", value: "" });

  return (
    <Modal
      onDismiss={() => {
        onCloseModal()
      }}
      visible={isShowing("controlsSurvey")}
      size="max"
      header={showChangesWarning ? <CancelWarning
        showWarning={showChangesWarning}
        onDismiss={() => {
          setWarningText('');
          setShowChangesWarning(false);
        }}
        warningText={warningText}
      /> : selectedEntity.site}
      footer={<ColumnLayout columns={2}>
        <Box float="left">
          {state.lastUpdated != "" ? `Last Updated: ${state.lastUpdated}` : ""}
        </Box>
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => {
              onCloseModal();
            }}>
              Cancel
            </Button>
            {state.surveyQuestions.some((s) => s.is_utilized == -1 || s.effective === -1) && <Button
              variant="primary"
              onClick={(_event) => {
                if (state.surveyQuestions.some((s) => s.is_utilized !== -1 && s.effective === -1)) {
                  setWarningText('Please pick values for both "utilized" and "effective"');
                  setShowChangesWarning(true);
                  return false;
                }
                postPartialSurvey();
                hideModals();
                setShowChangesWarning(false);
                refreshEntitiesAndManagers();
              }}
            >
              Save
            </Button>
            }
            <Button
              variant="primary"
              onClick={(_event) => {
                if (state.surveyQuestions.some((s) => (s.is_utilized === -1 || s.effective === -1))) {
                  setWarningText('All fields must be completed before submitting. Click "Save" to save your work');
                  setShowChangesWarning(true);
                  return false;
                }
                postSurveyAnswers();
                hideModals();
                refreshEntitiesAndManagers()
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      </ColumnLayout>
      }
    >
      <Table
        {...collectionProps}
        columnDefinitions={[
          {
            id: "category_type_id",
            header: "Category",
            cell: (e: SurveyViewOfControlType) => <span>{controlCategories.find((cc) => cc.id === e.category_type_id)?.name}</span>,
            isRowHeader: true,
          },
          {
            id: "control",
            header: "Control name",
            cell: (e: SurveyViewOfControlType) => <Popover
              dismissButton={true}
              position="top"
              size="large"
              triggerType="custom"
              content={e.generalDescription}
            >
              {e.control_name} <Icon name="status-info" />
            </Popover>,
            isRowHeader: true,
          },
          {
            id: "owner",
            header: "Owner",
            cell: (e: SurveyViewOfControlType) => e.owner,
          },
          {
            id: "is_utilized",
            header: "Is Utilized",
            cell: (e: SurveyViewOfControlType) => <Select
              selectedOption={IsUtilizedOptions.filter((f) => parseInt(f.value) == e.is_utilized)[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== "-1") {
                  dispatch({
                    Type: "UPDATE_ENTITY_CONTROL_IS_UTILIZED", Cargo: {
                      isUtilized: parseInt(detail.selectedOption.value!),
                      controlId: e.control_id
                    }
                  });
                  setHasPendingChanges(true);
                  setShowChangesWarning(false);
                  setWarningText('');
                }
              }
              }
              options={IsUtilizedOptions}
            />,
            isRowHeader: true,
            width: 180,
          },
          {
            id: "effective",
            header: <Popover
              fixedWidth
              header="Control Effectiveness Definitions"
              size="large"
              position="bottom"
              content={
                <EffectivenessDefinitionsLayout />
              }
            >Effective</Popover>,
            cell: (item: SurveyViewOfControlType) => {

              const optionsToShow = (item.is_utilized == IsUtilizedValues.PARTIAL || item.is_utilized == IsUtilizedValues.YES) ? EffectivenessOptions.filter((eo) => eo.value !== "0") : EffectivenessOptions;
              const selOption = EffectivenessOptions.find((efOpt) => parseInt(efOpt.value) == item.effective)!;
              return (<Select
                autoFocus={true}
                disabled={item.is_utilized === IsUtilizedValues.NOT_SET
                  || item.is_utilized === IsUtilizedValues.N_A
                  || item.is_utilized === IsUtilizedValues.NO}
                options={optionsToShow}
                selectedOption={selOption}
                placeholder={EffectivenessOptions.find((efOpt) => efOpt.value == item.effective.toString())?.label}
                onChange={(e) => {
                  //Don't allow setting the value to "choose effectiveness"
                  if (e.detail.selectedOption.value !== "-1") {
                    setHasPendingChanges(true);
                    dispatch({ Type: "UPDATE_ENTITY_CONTROL_EFFECTIVENESS", Cargo: { effectiveness: parseInt(e.detail.selectedOption.value!), controlId: item.control_id } });
                  }
                  setShowChangesWarning(false);
                  setWarningText('');
                }}
              />)
            },
            isRowHeader: true,
          }
        ]}
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Select
                  selectedOption={selectedCopyFromOption!}
                  onChange={({ detail }) => {
                    setSelectedCopyFromOption((copyFromOptions.find((co) => co.value === detail.selectedOption.value)));
                  }}
                  options={copyFromOptions}
                  filteringType="auto"
                  placeholder="Copy from another survey"
                />
                <Button onClick={(_evt) => {
                  copyFromExistingSurvey(selectedCopyFromOption?.value || "");
                }}>Import</Button>
              </SpaceBetween>}
          ></Header>}

        enableKeyboardNavigation
        items={itemsFromUseCollection}
        loadingText="Loading resources"
        wrapLines
        trackBy="control_id"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find a risk"
            filteringAriaLabel="Filter risks"
          />
        }



      />

    </Modal>
  );
};

export default ControlSurveyModal;
